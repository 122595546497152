
//export const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;
export const API_BASE_URL = 'https://rc1.mainbitpreproduccion.shop';

export const API_ENDPOINTS = {
    getFeaturedProducts: '/api/v1/shop/featuredProducts',
    getNewProducts: '/api/v1/shop/newProducts',
    getCategories: '/api/v1/shop/categories',
    userProfile: '/api/v1/auth/login',
    getUserData: '/api/v1/customer/profile',
    getProduct: '/api/v1/shop/productBySlug/', //after all send id of product
    register: '/api/v1/auth/register',
    logout: '/api/v1/auth/logout',
    addProductToCart: '/api/v1/customer/productToShoppingCart', 
    getCart: '/api/v1/customer/shoppingCart',
    createNewCart: '/api/v1/customer/createNewCart',
    refreshToken: '/api/v1/auth/refreshToken',
    getProductCategory: '/api/v1/shop/categories-slug/',
    verification: '/api/v1/auth/sendVerificationEmail',
    removeItemFromCart: '/api/v1/customer/deleteProductFromShoppingCart',
    getAddresses: '/api/v1/customer/shippingAddresses',
    applyCoupon: '/api/v1/order/coupon',
    processOrder: '/api/v1/order',
    removeCoupon: '/api/v1/order/deleteCoupon',
    getShippingMethods: '/api/v1/customer/shippingMethods',
    applyShippingMethod: '/api/v1/order/shippingMethod',
    saveAddress: '/api/v1/customer/address',
    addProductToWishlist: '/api/v1/customer/productToWishlist',
    getWishlist: '/api/v1/customer/wishlist',
    removeProductFromWishlist: '/api/v1/customer/deleteProductFromWishlist',
    createProductReview: '/api/v1/customer/review',
    deleteReview: '/api/v1/customer/review/',
    searchProduct: '/api/v1/shop/search',
    faq: '/api/v1/shop/faq',
    deleteAddress: '/api/v1/customer/deleteAddress',
    updateShoppingCart: '/api/v1/customer/updateShoppingCart',
    validationSMS: '/api/v1/customer/2fa',
    validateCode: '/api/v1/customer/validateCode',
    updateCustomer: '/api/v1/customer/updateCustomer',
    newCustomer: '/api/v1/customer/completeRegistration',
    relatedProducts: '/api/v1/shop/relatedProducts/',
    ticketsReasons:'/api/v1/shop/ticketsReasons',
    createTicket:'/api/v1/shop/ticket',
    getTickets:'/api/v1/customer/tickets',
    search:'/api/v1/shop/searchProducts',
    getAverageRating:'/api/v1/shop/getAverageRating',
    getPercentageRatings:'/api/v1/shop/percentageRatings',
    comparableProducts: '/api/v1/shop/comparableProducts',
    getOrders: '/api/v1/customer/orders',
    getRewards: '/api/v1/customer/getRewards',
};
