/* eslint-disable */
import axios from 'axios'
import { API_BASE_URL } from '@/config/config';
import store from './store';
import router from "./router";
import { useToast } from "vue-toastification";
import { isTokenExpired } from '@/utils/jwt';

const axiosClient = axios.create({baseURL: API_BASE_URL});
const toast = useToast();

axiosClient.interceptors.request.use(function (config) {
          store.commit('loading', true);
          config.headers.Authorization = `Bearer ${store.state.user.token}`
          config.data = {
               ...(config.data || {}),
               email: store.state.email, // Añadir o modificar datos aquí
               access_token: store.state.user.token
          };
          const tokenExpired = isTokenExpired(store.state.user.token);
          store.state.user.tokenExpired = tokenExpired
          return config;
     }, 
     (error) => {
          store.commit('loading', false);
          return Promise.reject(error);
     });

axiosClient.interceptors.response.use(function (response) {
     store.commit('loading', false);
     return response;
     }, error => {
          store.commit('loading', false);
     
     // if(error.response.data.error === 'Token expired'){
     //      sessionStorage.removeItem('TOKEN');
     //      sessionStorage.removeItem('REFRESH_TOKEN');
     //      store.state.tokenExpired = true;
     //      router.push({name: 'Sign In'})
     //      toast.error("Es necesario iniciar sesión nuevamente.")
     // }
     //toast.error(error.response.data.messageForUser)
    /* if(error.response.data.error === 'Error in logto'){
          if(!store.state.name && !store.state.date_of_birth && store.state.authenticated){
               router.push({name: 'Sign Up'})
               window.location.href = '/customer/register';
          }else{
               router.push({name: 'LogtoError'})
          }
          
     }*/
     if(error.response && error.response.data){
          if(error.response.data.error === "User not found on database."){
               router.push({name: 'Sign Up'})
          }
          if(error.response.data.error === "Token expired"){
               //|| error.response.data.error === 'No token provided' || error.response.status === 401
               // Eliminar el token de sessionStorage y localStorage
               sessionStorage.removeItem('TOKEN');
               localStorage.removeItem('TOKEN');
               // Redirigir al usuario a la página de inicio de sesión
               window.location.href = '/customer/login';
          }
     }
     if (error.response && error.response.status === 401) {
          if(error.response.data.messageForUser){
               toast.error(error.response.data.messageForUser);
          }
     
          if(error.response.data.messager){
               toast.error(error.response.data.message);
          }
          /*sessionStorage.removeItem('TOKEN')
          if(store.state.user.tokenFresh){
               store.dispatch('refreshToken')
          }*/
          return axios.AxiosError;
     }
     if (error.response) {
          if(error.response.status === 401 || error.response.status !== 200 && error.response.data.message){
               if(error.response.data.messageForUser){
                    //toast.error(error.response.data.messageForUser)
               }else{
                    //toast.error(error.response.data.message)
               }
               sessionStorage.removeItem('TOKEN')
               router.push({name: 'Sign In'})
          }
     } else if (error.response && error.response.status === 404) {
          router.push({name: 'NotFound'})
     } 
     if(error.response){
          if (error.response.data.message == 'Invalid token' || error.response.status === 403){
               sessionStorage.removeItem('TOKEN')
               toast.error("Sesión expirada")
               if(store.state.user.tokenFresh){
                    store.dispatch('refreshToken')
               }
          }
          if(error.response.data.message == 'Email verification required'){
               toast.error("Se requiere verificación por correo electrónico")
               router.push({name: 'Welcome'})
          }
     }
          return error;
});


export default axiosClient;

