<template>
    <div class="wrapper-main-mb">
        <!-- Start Breadcrumb Area  -->
        <div class="axil-breadcrumb-area">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-12 col-md-8">
                        <div class="inner">
                            <ul class="axil-breadcrumb">
                                <li class="axil-breadcrumb-item"><router-link :to="{ name: 'Profile' }">MI
                                        CUENTA</router-link>
                                </li>
                                <li class="separator"></li>
                                <li class="axil-breadcrumb-item active" aria-current="page">MIS PEDIDOS</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <br>
        <!-- End Breadcrumb Area  -->
        <div v-if="isLoading">
            <PlaceHolder></PlaceHolder>
        </div>
        <div v-else class="tab-pane" id="nav-orders" role="tabpanel" style="padding: 0 20px;">
            <div class="axil-dashboard-order">
                <div class="card-order c1" v-for="(order, odr) in orders" :key="odr">
                    <router-link :to="{ name: 'Order Detail', params: { 'id': order.payment_attempt.order } }" class="text-dark router-link">
                        <div class="row">
                            <div class="col-8 number-order"><b>{{ order.payment_attempt.order }}</b></div>
                            <div class="col-4"><b class="order-status">{{ order.status }}</b></div>
                        </div>
                        <div class="row">
                            <div class="col-8 order-date">{{ $filters.inDate(order.created_at) }}</div>
                            <div class="col-4"><h4><b>{{ $filters.money(order.grand_total) }}</b></h4></div>
                        </div>
                        <div>
                            <h6>{{ order.global_status.description }}</h6>
                        </div>
                    </router-link>
                </div>
                <div class="info" v-if="orders.length === 0">
                    <h3 class="data name-order text-dark text-center">No hay pedidos aún</h3>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { useStore } from "vuex";
import { computed } from "vue";
import store from "@/store";
import { useRouter } from "vue-router";
import PlaceHolder from '@/components/PlaceHolder/MyOrders.vue'
import { mapState } from 'vuex'

export default {
    data: () => ({
        store: useStore(),
        router: useRouter(),
        user: {},
        orders: [],
    }),
    components: {
        PlaceHolder
    },
    created() {
        if (store.state.user.token) {
            this.getUserData()
        } else {
            this.user = computed(() => '');
            if (store.state.user.tokenFresh) {
                store.dispatch("refreshToken")
                    .then((res) => {
                        console.log(res);
                    }).catch((err) => {
                        console.log(err);
                    });
            }
        }
    },
    mounted() {
        this.getOrders();
    },
    computed:{
        ...mapState(['isLoading'])
    },
    methods: {
        getOrders(){
            store.dispatch("getOrders").then((res) => {
                this.orders = res.data.payload;
            })
            .catch((err) => {
                console.log(err)
            })
        },
        getUserData() {
            store.dispatch("getUser").then(() => {
                //this.orders = this.store.state.user.data.orders;
                if (this.store.state.user.token) {
                    this.user = computed(() => store.state.user.data);

                } else {
                    this.user = computed(() => '');
                }
            })
            .catch((err) => {
                console.log(err)
            })
        },
    }
}
</script>
