<template>
    <div>
        <div class="axil-checkout-area axil-section-gap">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <!--div class="axil-checkout-notice">
                            <div class="axil-toggle-box">
                                <div class="toggle-bar">
                                    <b-button variant="outline-primary" @click="visible = !visible" class="m-1">
                                        <i class="fas fa-pencil"></i>  Tienes un cupon? ingresalo aqui! <i class="fas fa-angle-down"></i>
                                    </b-button>
                                    <b-collapse id="collapse-2" v-model="visible">
                                        <div class="form-group ps-1 mt-auto">
                                            <input type="text" placeholder="Ingresa el cupon">
                                        </div>
                                    </b-collapse>
                                </div>

                                <div class="axil-checkout-coupon toggle-open">
                                    <p>If you have a coupon code, please apply it below.</p>
                                    <div class="input-group">
                                        <input placeholder="Enter coupon code" type="text">
                                        <div class="apply-btn">
                                            <button type="submit" class="axil-btn btn-bg-primary">Apply</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div-->
                        <div class="axil-checkout-billing">
                            <!--h4 class="title mb--40">Elige o crea una nueva direccion</h4>
                            <div class="col-4">
                                <b-button size="lg" @click.prevent="newAddress = !newAddress" variant="outline-primary" class="m-1">Crear direccion</b-button>
                            </div>
                            <div class="col-12 pt-4" v-if="!newAddress">
                                <div class="order-shipping">
                                    <h3 class="title mb--20 text-start">Selecciona una direccion</h3>
                                    <b-form-group class="input-group text-start" v-slot="{ ariaDescribedby }"
                                        v-for="address in addresses" :key="address.id">
                                        <b-form-radio-group
                                        class="pt-3"
                                        :id="`radio-group-${address.id}`"
                                        v-model="addressSelected"
                                        :aria-describedby="ariaDescribedby"
                                        name="radio-sub-component">
                                            <b-form-radio :value="address.id">
                                                {{ address.address1 }} <br>
                                                {{ address.city }}
                                                {{ address.state }} <br>
                                                {{ address.country }} {{ address.postcode }} <br>
                                            </b-form-radio>
                                        </b-form-radio-group>
                                    </b-form-group>
                                </div>
                            </div>
                            <div class="col-12 pt-5 mt-5" v-else>
                                <Form @submit="onSubmit">
                                    <div class="form-group">
                                        <label>Nombre <span>*</span></label>
                                        <Field name="name" type="name" :rules="validName" />
                                        <ErrorMessage name="name" style="color: red"/>
                                    </div>
                                    <div class="form-group">
                                        <label>Apellidos <span>*</span></label>
                                        <Field name="last_name" type="text" :rules="validLastName" />
                                        <ErrorMessage name="last_name" style="color: red"/>
                                    </div>
                                    <div class="form-group">
                                        <label>E-mail <span>*</span></label>
                                        <Field name="email" type="email" :rules="validateEmail" />
                                        
                                        <ErrorMessage name="email" style="color: red"/>
                                    </div>
                                    <div class="form-group">
                                        <label>Codigo Postal <span>*</span></label>
                                        <Field name="zipcode" type="number" :rules="validateZipcode" />
                                        <ErrorMessage name="zipcode" style="color: red"/>
                                    </div>
                                    <div class="form-group">
                                        <label>Colonia/Fraccionamiento/Barrio <span>*</span></label>
                                        <Field name="location" type="text" :rules="validateLocation" />
                                        <ErrorMessage name="location" style="color: red"/>
                                    </div>
                                    <div class="form-group">
                                        <label>Calle <span>*</span></label>
                                        <Field name="street" type="text" :rules="validateGeneral" />
                                        <ErrorMessage name="street" style="color: red"/>
                                    </div>
                                    <div class="form-group">
                                        <label>Ciudad <span>*</span></label>
                                        <Field name="city" type="text" :rules="validateGeneral" />
                                        <ErrorMessage name="city" style="color: red"/>
                                    </div>
                                    <div class="form-group">
                                        <label>Estado <span>*</span></label>
                                        <Field name="state" type="text" :rules="validateGeneral" />
                                        <ErrorMessage name="state" style="color: red"/>
                                    </div>
                                    <div class="form-group">
                                        <label>Telefono <span>*</span></label>
                                        <Field name="phone" type="number" :rules="validatePhone"/>
                                        <ErrorMessage name="phone" style="color: red"/>
                                    </div>
                                    <button class="axil-btn btn-bg-primary viewcart-btn">Crear direccion</button>
                                </Form>
                            </div-->
                            <div class="col-12 pt-5 text-start">
                                <div class="axil-order-summery order-checkout-summery">
                                    <h5 class="title mb--20">Tu carrito</h5>
                                    <div v-if="visibleAlert && recommendedProducts.length > 0 && !this.existProduct" class="alert-box">
                                        <h4>Producto recommendado</h4>
                                        <router-link :to="{
                                             name: 'Show',
                                             params: { 'id': recommendedProducts[0].url_key }
                                            }" style="text-decoration: none !important;color: #000 !important;">
                                        <div class="row">
                                            <div class="col-6">
                                                <img :src="recommendedProducts[0].images[0]['path']" alt="Commodo Blown Lamp">
                                            </div>
                                            <div class="col-6">
                                                <h4>{{ $filters.truncate(recommendedProducts[0].name, 50) }}</h4>
                                                <h4>{{ $filters.money(recommendedProducts[0].best_price) }}</h4>
                                            </div>
                                        </div>
                                        </router-link>
                                        <div class="row">
                                            <div class="col-6">
                                                <button type="button" class="btn btn-info" style="font-size: 10px;width: 100%;height: auto;" @click="closeAlert">No gracias</button>
                                            </div>
                                            <div class="col-6">
                                                <button type="button" class="btn btn-danger" @click.prevent="add(recommendedProducts[0].id)" style="font-size: 10px;width: 100%;height: auto;">Añadir al carrito</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="summery-table-wrap">
                                        <table class="table summery-table">
                                            <thead>
                                                <tr>
                                                    <th>Producto</th>
                                                    <th></th>
                                                    <th>Subtotal</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr class="order-product" v-for="item in user.cart.items" :key="item.id">
                                                    <div class="item-img" v-if="item.images">
                                                        <a :href="item.product_details.simple_fields.url_key" v-if="item.images[0]">
                                                                <img :src="item.images[0]['path']"
                                                                    alt="Commodo Blown Lamp">
                                                        </a>
                                                        <a :href="item.product_details.simple_fields.url_key" v-else>
                                                                <div class="col-6"></div>
                                                        </a>
                                                        <br>
                                                        <a class="close-btn" :href="'#modal' + item.product_details.simple_fields.id">
                                                                <i class="fas fa-times"></i>
                                                        </a>
                                                    </div>
                                                    <td>{{ $filters.truncate(item.name, 30) }} <span class="quantity"> x{{ item.quantity }}</span></td>
                                                    <td>{{ $filters.money(item.base_price) }}</td>
                                                </tr>
                                                <!--tr class="order-shipping">
                                                    <td colspan="2">
                                                        <div class="shipping-amount">
                                                            <span class="title">Metodo de envio</span>
                                                            <span class="amount">{{ $filters.money(shippCost) }}</span>
                                                        </div>
                                                        <b-form-group class="input-group" v-slot="{ ariaDescribedby }"
                                                            v-for="(shipping, index) in shippingMethods" :key="index">
                                                            <b-form-radio-group
                                                            :id="`radio-group-${index}`"
                                                            v-model="shippingSelected"
                                                            :aria-describedby="ariaDescribedby"
                                                            @change="applyShipping()"
                                                            :name="`shipping-method-${shipping.code}`">
                                                                <b-form-radio :value="shipping.code">
                                                                    {{ shipping.title }} <br>
                                                                    <b>{{ shipping.description }}</b>
                                                                </b-form-radio>
                                                            </b-form-radio-group>
                                                        </b-form-group>
                                                    </td>
                                                </tr-->
                                                <tr class="order-total">
                                                    <td>Total</td>
                                                    <td class="order-total-amount">
                                                        {{ $filters.money(user.cart.grand_total) }}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <!--div class="order-payment-method">
                                        
                                        <div class="single-payment">
                                            <div class="input-group">
                                                <input type="radio" id="radio5" name="payment">
                                                <label for="radio5">Cash on delivery</label>
                                            </div>
                                            <p>Pay with cash upon delivery.</p>
                                        </div>
                                    </div-->
                                    

                                    <div class="axil-checkout-notice">
                                        <div class="axil-toggle-box">
                                            <div class="toggle-bar">
                                                <b-button variant="outline-primary" @click="visible = !visible" class="m-1">
                                                    <i class="fas fa-pencil"></i>  Tienes un cupon? ingresalo aqui! <i class="fas fa-angle-down"></i>
                                                </b-button>
                                                <b-collapse id="collapse-2" v-model="visible">
                                                    <div class="form-group ps-1 mt-auto">
                                                        <input v-model="coupon" type="text" placeholder="Ingresa el cupon">
                                                    </div>
                                                    <div class="apply-btn">
                                                        <button type="submit" @click="processCoupon()" class="axil-btn btn-bg-primary">Apply</button>
                                                    </div>
                                                </b-collapse>
                                            </div>
                                        </div>
                                    </div>
                                    <button type="submit" class="axil-btn btn-bg-secondary checkout-btn" @click="processOrder()">Pagar</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
//import { Form, Field, ErrorMessage } from 'vee-validate';
import store  from '@/store'
import { mapState } from 'vuex'
import { useToast } from "vue-toastification";
import axiosClient from '@/axios';

export default {
    name: 'Checkout-View',
    components: {
        //Form,
        //Field,
        //ErrorMessage
    },
    data: () => ({
        toast: useToast(),
        visible: false,
        addresses: [],
        newAddress: false,
        addressSelected: "",
        shippingMethods: [],
        shippingSelected: '',
        shippCost: 0,
        visibleAlert: true,
        existProduct: false,
        coupon: '',
    }),
    computed: {
        validation() {
            return this.form.name.length  > 4 && this.form.name.length  < 13
        },
        ...mapState(['user','recommendedProducts'])
    },
    methods: {
        processOrder(){
            axiosClient.post('https://rc1.mainbitpreproduccion.shop/api/v1/checkout')
            .then((res) => {
                console.log(res)
                if(res.data.shopRoute){
                        window.location.href = res.data.shopRoute
                }
            })
            .catch(error => console.error('Error:', error));
        },
        processCoupon(){
            if(this.coupon.length > 2){
                this.toast.success('Cupon aplicado correctamente');
            }
        },
        add(id) {
            store.dispatch("addProductToCart", {
                quantity: 1,
                product_id: id,
                is_buy_now: 0,
                inventory_id: 0
            })
            .then((res) => {
                if(!res.data.success){
                    this.toast.error(res.data.errors[0])
                }else{
                    this.closeAlert();
                    this.toast.success('Articulo añadido al carrito')
                }
                store.dispatch("getCart")
            }).catch((err) => {
                console.log(err);
            });
        
        },
        closeAlert() {
            this.visibleAlert = false;
        },
        applyShipping(){
            store.dispatch('applyShippingMethod', {
                shippingCode: this.shippingSelected
            })
            .then((res) => {
                this.toast.success(res.data.messageForUser)
                this.shippCost = res.data.shippingCost
            }).catch((err) => {
                this.toast.error(err)
            });
        }, 
        onSubmit(values){
            let address = {
                "company_name": values.name + ' ' + values.last_name,
                "first_name": values.name, 
                "last_name": values.last_name, 
                "address1": values.street,
                "address2": values.location,
                "city": values.city,
                "postcode": values.zipcode,
                "state": values.state,
                "country": "MX",
                "phone": values.phone,
                "from_checkout": true,
                "cart_id": store.state.user.cart.id
            }
            store.dispatch('saveAddress', address)
            .then((res) => {
                this.toast.success(res.data.messageForUser)
                this.newAddress = false;
                this.getAddress();
            }).catch((err) => {
                console.log(err);
            });
        },
        validateEmail(value) {
            // if the field is empty
            if (!value) {
                return 'Campo requerido';
            }
            // if the field is not a valid email
            const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
            if (!regex.test(value)) {
                return 'Ingresa un email valido';
            }
            // All is good
            return true;
        },
        validName(val) {
            if (!val) {
                return 'Campo requerido';
            }
            if (val.length < 4) {
                return 'Minimo 4 caracteres';
            }
            return true;
        },
        validLastName(val) {
            if (!val) {
                return 'Campo requerido';
            }
            if (val.length < 4) {
                return 'Minimo 4 caracteres';
            }
            return true;
        },
        validateZipcode(val) {
            if (!val) {
                return 'Campo requerido';
            }
            if (val.length < 4) {
                return 'Minimo 4 caracteres';
            }

            if (typeof value === 'number') {
                return 'Solo se aceptan numeros';
            }
            return true;
        },
        validateLocation(val) {
            if (!val) {
                return 'Campo requerido';
            }
            if (val.length < 4) {
                return 'Minimo 4 caracteres';
            }

            return true;
        },
        validatePhone(val) {
            if (!val) {
                return 'Campo requerido';
            }
            if (val.length < 10) {
                return 'Minimo 10 caracteres';
            }

            return true;
        },
        validateGeneral(val) {
            if (!val) {
                return 'Campo requerido';
            }
            if (val.length < 4) {
                return 'Minimo 10 caracteres';
            }

            return true;
        },
        onReset() {
            this.form = {
                email: '',
                name: '',
                last_name: '',
                food: null,
                checked: [],
                postcode: '',
                city: '',
                street: '',
                state: '',
                location: '',

            }
            this.$nextTick(() => {
            })
        },
        async getAddress() {
            await store.dispatch('getAddresses')
            .then((res) => {
                this.addresses = res.data.addresses
                this.setLastAddress(res.data.addresses);

            }).catch((err) => {
                console.log(err);
            });
        },
        setLastAddress(addresses){
            if(addresses){
                let lastAddress = addresses[addresses.length - 1];
                this.addressSelected = lastAddress.id;
            }
            return;
        }
    },
    beforeCreated(){
    },
    mounted(){
        if(this.recommendedProducts.length > 0){
            this.user.cart.items.forEach((id) => {
                if(this.recommendedProducts[0].id === id.product_id){
                    this.existProduct = true;
                }else{
                    this.existProduct = false;
                }
            });
        }
        this.getAddress();
        store.dispatch('getShippingMethods')
        .then((res) => {
            this.shippingMethods = res.data.shippingMethods
        }).catch((err) => {
            console.log(err);
        });
    }
}
</script>